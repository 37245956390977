<template>
  <span v-if="getUnreadNotifications" class="notification-badge">
    {{ getUnreadNotifications }}
  </span>
</template>

<script>
import { onMessage } from "firebase/messaging";

export default {
  name: "NotificationBadge",
  data: () => ({
    notification: {
      unread_notifications: 0,
      updateOrderList: false
    }
  }),
  mounted() {
    if (localStorage.getItem("unread_notifications")) {
      this.notification.unread_notifications = +localStorage.getItem(
        "unread_notifications"
      );
    }
  },
  computed: {
    getUnreadNotifications() {
      return this.notification.unread_notifications;
    }
  },
  methods: {
    setUnreadNotificationToZero() {
      this.notification.unread_notifications = 0;
    },
    incrementUnreadNotification() {
      this.notification.unread_notifications++;
    },
    saveUnreadNotification() {
      localStorage.setItem(
        "unread_notifications",
        this.notification.unread_notifications
      );
    },
    messageReceive() {
      onMessage(this.$messaging, payload => {
        console.log("msg received", payload);
        this.incrementUnreadNotification();
        this.saveUnreadNotification();
        let audio = new Audio();
        audio.src = "/public/new_order_notification.mp3";
        audio.play();
        this.setUpdateOrderState(true);
      });
    }
  },
  created() {
    this.messageReceive();
  }
};
</script>

<style lang="css" scoped>
.notification-badge {
  border-radius: 50%;
  background-color: red;
  color: white;
  padding: 2px;
  width: 23px;
  height: 23px;
  text-align: center;
  display: inline-block;
}
</style>
