import Vue from 'vue'
import { initializeApp } from "@firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import store from '../store/index';

const FCM = {
  install(Vue) {
    Vue.prototype.initFCMMessaging = async function() {
      let firebaseConfig = {
        apiKey: Vue.prototype.$firebaseAPIKey,
        projectId: Vue.prototype.$firebaseProjectId,
        messagingSenderId: Vue.prototype.$firebaseSenderID,
        appId: Vue.prototype.$firebaseAppId
      };

      // Initialize Firebase
      const firebaseApp = initializeApp(firebaseConfig);
      Vue.prototype.$messaging = getMessaging(firebaseApp);
      Vue.prototype.$fcm_ready = true;
      if ("serviceWorker" in navigator) {
        try {
          const registrations = await navigator.serviceWorker.getRegistrations()
          for (let register in registrations) {
            if (typeof registrations[register].unregister != "undefined")
              registrations[register].unregister();
          }
        } catch (error) {
          console.log("Error: ", error)
        }
        navigator.serviceWorker
          .register("/public/firebase-messaging-sw.js")
          .then(function(registration) {
            console.log(
              "Registration successful, scope is:",
              registration.scope
            );
          })
          .catch(function(err) {
            console.log("Service worker registration failed, error:", err);
          });
      }
    };
  }
};

Vue.use(FCM);
