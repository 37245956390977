<template>
  <span v-if="count > 0" class="notification-badge"> {{ count }} </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RequestNotificationBadge",
  props: {
    getters: {
      type: Array,
      deafult: () => []
    }
  },
  data: () => ({ title: "" }),
  computed: {
    ...mapState({
      count(state, getters) {
        return this.getters.reduce(function(all, get) {
          return all + getters[`${get.namespace}/${get.map}`];
        }, 0);
      }
    })
  }
};
</script>

<style scoped>
.notification-badge {
  direction: ltr !important;
  border-radius: 50%;
  background-color: red;
  color: white;
  padding: 2px;
  width: 23px;
  height: 23px;
  text-align: center;
  display: inline-block;
}
</style>
