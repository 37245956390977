const state = {
  payload: {},
}
const getters = {
  getpayload: (state) => {
    return state.payload;
  }
};

const actions = {
  setpayload({ commit }, value) {
    commit('setpayload', value);
  }
}

const mutations = {
  setpayload(state, value) {
    state.payload = value;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
