import Vue from 'vue'
import Vuex from 'vuex'
import ticket from "./modules/ticket";
import fcm from "./modules/fcm";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    ticket,
    fcm
  },
  strict: debug,
  plugins: []
})
