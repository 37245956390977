import { getToken } from "firebase/messaging";
import Vue from "vue";

import { API_ORIGIN } from "../../services";
import { authorizedReq } from "../../services/common";

export default {
  methods: {
    // getToken() {
    //     this.$messaging.getToken().then((currentToken) => {
    //         if (currentToken) {
    //             this.sendTokenToServer(currentToken);
    //         } else {
    //             this.setTokenSentToServer(false);
    //         }
    //     }).catch((err) => {
    //         this.setTokenSentToServer(false);
    //     });
    // },
    setTokenSentToServer(sent) {
      window.localStorage.setItem("sentToServer", sent ? "1" : "0");
    },
    sendTokenToServer(token) {
      let data = {
        registration_id: token,
        type: "web"
      };
      authorizedReq
        .post(`${API_ORIGIN}/register-notif-token/`, data)
        .then(() => {
          this.setTokenSentToServer(true);
        })
        .catch(() => {
          this.setTokenSentToServer(false);
        });
    },
    // tokenRefresh() {
    //     this.$messaging.onTokenRefresh(() => {
    //         this.$messaging.getToken().then((refreshedToken) => {
    //             // Indicate that the new Instance ID token has not yet been sent to the
    //             // app server.
    //             this.setTokenSentToServer(false);
    //             // Send Instance ID token to app server.
    //             this.sendTokenToServer(refreshedToken);
    //         }).catch((err) => {
    //             console.log('Unable to retrieve refreshed token ', err);
    //         });
    //     });
    // },
    requestPerms() {
      //this.$messaging.requestPermission().then(() => {
      if (!window.localStorage.getItem("sentToServer")) {
        //this.$messaging.getToken()
        getToken(this.$messaging, {
          vapidKey: Vue.prototype.$firebasePublicKey
        })
          .then(token => {
            this.sendTokenToServer(token);
          })
          .catch(() => {
            console.log("Unable to get permission to notify.");
          });
      }
      // }).catch((err) => {
      //     console.log('Unable to get permission to notify.', err)
      // })
    },
    fcmInit() {
      this.requestPerms();
      //this.tokenRefresh();
    }
  }
};
